<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'washing-facility-plan-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'washingFacilityPlanInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'washingFacilityPlanInfo', icon: 'info', label: '세척·세안 시설', component: () => import(`${'./washingFacilityPlanInfo.vue'}`) }, // 세척·세안 시설
        { name: 'washingFacilityPlanSafety', icon: 'info', label: '안전보호장구/비상방제물품', component: () => import(`${'./washingFacilityPlanSafety.vue'}`) }, // 안전보호장구/비상방제물품
        { name: 'washingFacilityPlanPid', icon: 'tab', label: '도면', component: () => import(`${'./washingFacilityPlanPid.vue'}`) }, // 세척·세안 시설 및 안전 보호장구 설치계획도
        { name: 'washingFacilityPlanDoc', icon: 'assignment', label: '문서', component: () => import(`${'./washingFacilityPlanDoc.vue'}`) } // 세척·세안 시설 및 안전 보호장구 설치계획 문서
      ]
    },

  }
};
</script>